import React from "react";

import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <p>&copy; {new Date().getFullYear()}. All rights reserved.</p>
    <div className="footer-div">
      <a
        className="App-link"
        href="mailto:anup@anup.name.np"
        target="_blank"
        rel="noopener noreferrer"
      >
        Support Mail
      </a>
    </div>
  </footer>
);

export default Footer;
